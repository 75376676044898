import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { NESTED_IDS } from "../../../../utils/staticData";

export default function MicronutrientInputs({
  item,
  handleNestedChange,
  handleRemove,
}) {
  return (
    <div>
      <input
        style={{ width: "6rem" }}
        type="text"
        name="micronutrientInputs.value"
        value={item.value || ""}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <FontAwesomeIcon
        icon={faXmark}
        size="lg"
        onClick={() => handleRemove(NESTED_IDS.microinput, item.id)}
        style={{
          color: "rgb(241, 250, 238)",
          marginLeft: "0.2rem",
          cursor: "pointer",
        }}
      />
    </div>
  );
}

import { NavLink } from "react-router-dom";
import { useSendLogoutMutation } from "../../app/api/authAPISlice";
import styles from "./css/Nav.module.css";
import logo from "../../images/apple.png";
import { faRightFromBracket, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NavMobile() {
  const [sendLogout] = useSendLogoutMutation();

  return (
    <div className={styles.nav_container_mobile}>
      <div className={styles.nav_right_mobile}>
        <img src={logo} alt="Logo" className={styles.nav_logo_mobile} />
        <NavLink to="/dashboard" style={{ marginLeft: "4.5rem" }}>
          <FontAwesomeIcon icon={faHouse} color="rgb(241, 250, 238)" />
        </NavLink>
        <NavLink to="/" onClick={sendLogout} style={{ marginLeft: "4.75rem" }}>
          <FontAwesomeIcon
            icon={faRightFromBracket}
            color="rgb(241, 250, 238)"
          />
        </NavLink>
      </div>
    </div>
  );
}

export default NavMobile;

//React Hooks
import { useState } from "react";

//API
import { useUpdateItemMutation } from "../../app/api/listApiSlice";
import { useUpdateLongTermItemMutation } from "../../app/api/longTermApiSlice";

//styles
import styles from "./css/Button.module.css";

const SaveButton = ({ title, inputs, data, type }) => {
  const [loader, setLoader] = useState(false);
  const [updateItem, { isError, error }] = useUpdateItemMutation();
  const [updateLtItem] = useUpdateLongTermItemMutation();

  /// send state changes to database
  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateItem(inputs);

    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);

    if (data.ltentry) {
      await updateLtItem({
        firstName: data.firstName,
        lastName: data.lastName,
        age: data.age,
        pmhx: inputs.pmhx,
        upperDent: inputs.upperDent,
        lowerDent: inputs.lowerDent,
        foodAllergy: inputs.foodAllergy,
        foodIntolerance: inputs.foodIntolerance,
        inputFields: inputs.inputFields,
        supplementList: inputs.supplementList,
        adaptEquip: inputs.adaptEquip,
        ambulation: inputs.ambulation,
        eatSelfPerform: inputs.eatSelfPerform,
        eatSupportPerform: inputs.eatSupportPerform,
        portionSize: inputs.portionSize,
      });
    }
  };

  return (
    <div className={styles.save_button_container}>
      {!loader ? (
        <button type="button" className={styles.button} onClick={handleSubmit}>
          {title}
        </button>
      ) : (
        <span className={styles.loader}></span>
      )}
      {isError && (
        <p className={styles.upper_assess_save}>{error?.data?.message}</p>
      )}
    </div>
  );
};

export default SaveButton;

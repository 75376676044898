import { React, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setLTData } from "../startpage/startPageSlice";

//named import
import { letter } from "../../../utils/staticData";
import { useDeleteLongTermItemMutation } from "../../../app/api/longTermApiSlice";

//library imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";

//styles
import styles from "../../../css/LongTermLists.module.css";

const LongTermList = ({
  data,
  handleViewToggle,
  handleEditToggle,
  handleNewToggle,
}) => {
  const [letterFilter, setLetterFilter] = useState("");
  const dispatch = useDispatch();

  const auth = useAuth();

  const [deleteItem] = useDeleteLongTermItemMutation();

  const letterFilteredData = letterFilter
    ? data.filter(
        (item) => item.lastName.charAt(0).toLowerCase() === letterFilter
      )
    : data;

  const handleDelete = async (id) => {
    await deleteItem({ id: id });
  };

  return (
    <div className={styles.lt_list}>
      <div className={styles.lt_list_filter}>
        <span>Location:</span>
        <span className={styles.lt_location}>
          {auth.location.toUpperCase()}
        </span>
        <span style={{ marginLeft: "1rem" }}>Filter By Letter:</span>
        <select
          name="locationView"
          className={styles.select_lt_list}
          onChange={(e) => setLetterFilter((prev) => e.target.value)}>
          <option></option>
          {letter.map((l, i) => (
            <option key={i} value={l}>
              {l?.toUpperCase()}
            </option>
          ))}
        </select>
        {auth.status === "Admin" && (
          <button
            className={styles.button}
            style={{
              marginLeft: "15px",
              height: "1.5rem",
              width: "5rem",
              padding: "3.5px",
            }}
            onClick={() => handleNewToggle(true)}>
            Add New
          </button>
        )}
      </div>
      <div className={styles.lt_table}>
        <table>
          <tbody>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>DOB</th>
              <th></th>
              {auth.status === "Admin" && (
                <>
                  <th></th>
                  <th></th>
                  <th></th>
                </>
              )}
            </tr>
          </tbody>
          <tbody>
            {letterFilteredData
              ?.filter((item) => item.location === auth.location)
              .sort((a, b) => a.lastName.localeCompare(b.lastName))
              .map((item) => (
                <tr key={item._id} style={{ marginBottom: "10px" }}>
                  <td className={styles.long_term_list_space}>
                    {item.lastName}
                  </td>
                  <td className={styles.long_term_list_space}>
                    {item.firstName}
                  </td>
                  <td className={styles.long_term_list_space}>
                    {new Date(item.age).toLocaleDateString("en", {
                      timeZone: "utc",
                    })}
                  </td>
                  <td className={styles.long_term_list_space}>
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      onClick={() =>
                        dispatch(
                          setLTData({
                            lastName: item.lastName,
                            firstName: item.firstName,
                            dob: item.age,
                          })
                        )
                      }
                      style={{
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  {auth.status === "Admin" && (
                    <>
                      <td className={styles.long_term_list_space}>
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => handleEditToggle(item._id)}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                      <td className={styles.long_term_list_space}>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => handleViewToggle(true, item._id)}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                      <td className={styles.long_term_list_space}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={(e) => handleDelete(item._id)}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LongTermList;

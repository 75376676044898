import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StartPage from "./Pages/StartPage.jsx";
import Assessment from "./Pages/Assessment.jsx";
import MalnutritionPDF from "./features/standard/assessments/modal/MalnutritionPDF.jsx";
import Login from "./Pages/Login.jsx";
import Prefetch from "./app/auth/Prefetch.js";
import RequireAuth from "./app/auth/RequireAuth.js";
import Settings from "./Pages/Settings.jsx";
import Weights from "./Pages/Weights.jsx";
import { ROLES } from "./config/roles.js";
import PersistLogin from "./app/auth/PersistLogin.js";
import TwoFA from "./Pages/TwoFA.jsx";
import Layout from "./components/layout/Layout.jsx";
import RequiredFirstAuth from "./app/auth/RequiredFirstAuth.js";

export default function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route element={<RequiredFirstAuth />}>
            <Route exact path="verify" element={<TwoFA />} />
          </Route>

          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
              }>
              <Route element={<Prefetch />}>
                <Route path="dashboard" element={<StartPage />} />
                <Route path="assessment/:id" element={<Assessment />} />
                <Route
                  path="malnutritionscreen/:id"
                  element={<MalnutritionPDF />}
                />
                <Route path="settings" element={<Settings />} />
                <Route path="weights" element={<Weights />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

import { React, useState, useEffect } from "react";

//fetch components
import { useAddNewUserMutation } from "../../../app/api/usersApiSlice";

//helper functions
import { evaluatePasswordStrength } from "../../../utils/helperFunctions";

//components
import SaveButton from "../../../components/buttons/SaveButton";
import PasswordRules from "../../../components/sideMenus/PasswordRules";

//styles
import styles from "../../../css/Settings.module.css";

const AddUserName = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [strength, setStrength] = useState({ type: "", score: 0 });
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [roles, setRoles] = useState("Employee");
  const [allowedLocations, setAllowedLocations] = useState([]);

  const [addUser] = useAddNewUserMutation();

  useEffect(() => {
    setEmailErr(false);
  }, [email]);

  useEffect(() => {
    setPassErr(false);
  }, [strength.score]);

  const emailRegEx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

  const handleUserName = (e) => setUsername(e.target.value);
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setStrength(evaluatePasswordStrength(e.target.value));
  };
  const handleEmail = (e) => setEmail(e.target.value);
  const handleRoles = (e) => setRoles(e.target.value);
  const handleAllowedLocations = (e) => {
    const values = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    setAllowedLocations(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegEx.test(email)) return setEmailErr(true);
    if (strength.score < 4) return setPassErr(true);
    try {
      await addUser({ username, email, password, roles, allowedLocations });
      setUsername("");
      setEmail("");
      setPassword("");
      setRoles("Employee");
      setAllowedLocations([]);
      setStrength({ type: "", score: 0 });
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("All fields are required");
      } else if (err.status === 409) {
        setErrMsg("Duplicate username");
      } else {
        setErrMsg(err.data?.message);
      }
    }
  };

  return (
    <div className={styles.settings_outer_container}>
      <div className={styles.settings_inner_container} onSubmit={handleSubmit}>
        <h3>Add New User</h3>
        <div className={styles.add_new_user_inputs}>
          <span>Username: </span>
          <input
            type="text"
            name="username"
            value={username}
            maxLength="12"
            onChange={handleUserName}
          />
          <span>E-mail: </span>
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleEmail}
          />
          {emailErr && (
            <>
              <span></span>
              <span className={styles.add_new_user_email_error}>
                Not A Valid E-mail
              </span>
            </>
          )}
          <span>Password: </span>
          <input
            type="text"
            name="password"
            value={password}
            onChange={handlePassword}
          />
        </div>
        <div className={styles.add_new_user_strength_bar}>
          <meter max="4" value={String(strength.score)} />
          <small>{strength.type}</small>
          {passErr && <small>Stronger Password Required</small>}
        </div>

        <div className={styles.add_new_user_roles}>
          <span>Role: </span>
          <select name="roles" value={roles} onChange={handleRoles}>
            <option value="Employee">Employee</option>
            <option value="Manager">Manager</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
        <div className={styles.add_new_user_locations}>
          <span>Allowed Locations: </span>
          <select
            size="2"
            multiple
            value={allowedLocations}
            onChange={handleAllowedLocations}>
            <option value="bwt">Birchwood Terrace</option>
            <option value="wr">Wake Robin</option>
          </select>
        </div>
        <SaveButton title="Create" event={handleSubmit} />
        <span style={{ marginLeft: "10px" }}>{errMsg}</span>
      </div>
      {password.length > 0 && <PasswordRules />}
    </div>
  );
};

export default AddUserName;

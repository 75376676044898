import CopyToClipboard from "react-copy-to-clipboard";
import parse from "html-react-parser";
import { convert } from "html-to-text";

import DOMpurify from "dompurify";
import { malnutritionRiskFunction } from "../../../../utils/summaryHelperFunctions";
import { bwtProgressTemplate } from "../../../../utils/bwtProgressTemplate";
import { wrProgressTemplate } from "../../../../utils/wrProgressTemplate";
import useAuth from "../../../../hooks/useAuth";

//styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../../css/SummaryModal.module.css";
import buttonStyles from "../../../../components/buttons/css/Button.module.css";

const ProgressModal = ({ data, closeModal }) => {
  const { location } = useAuth();

  const name = data.firstName;
  const malnutRiskScreenName = malnutritionRiskFunction(data).malName;
  const malnutRiskNumber = malnutritionRiskFunction(data);

  let progressNote;

  if (location === "bwt") {
    progressNote = bwtProgressTemplate(data);
  }

  if (location === "wr") {
    progressNote = wrProgressTemplate(data);
  }

  const cleanedProgressNote = DOMpurify.sanitize(progressNote);
  const convertedProgressNote = convert(cleanedProgressNote, {
    selectors: [{ selector: "ul", options: { itemPrefix: " ❖ " } }],
  });
  const parsedProgressNote = parse(cleanedProgressNote);
  const foodAllergy = data.foodAllergy
    .map((item) => item.allergyname)
    .join(" / ");
  const foodIntolerance = data.foodIntolerance
    .map((item) => item.intolerancename)
    .join(" / ");

  let foodAllergyText;
  if (foodAllergy && foodIntolerance) {
    foodAllergyText = `${name} has a food allergy to ${foodAllergy} and food intolerance to ${foodIntolerance}.`;
  } else if (foodAllergy) {
    foodAllergyText = `${name} has a food allergy to ${foodAllergy}.`;
  } else if (foodIntolerance) {
    foodAllergyText = `${name} has a food intolerance to ${foodIntolerance}.`;
  } else {
    foodAllergyText = null;
  }

  const malnutritionCopy = name + malnutRiskScreenName.slice(38);

  return (
    <div className={styles.summary_modal_progress_container}>
      <div className={styles.summary_modal_title_row}>
        <div className={styles.modal_top_box}>
          <h4 className={styles.summary_modal_title}>Progress Note</h4>
          <CopyToClipboard text={convertedProgressNote} onCopy={(text) => text}>
            <button className={buttonStyles.button}>Copy Progress Note</button>
          </CopyToClipboard>
        </div>

        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Progress Note")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.modal_progress_note} style={{ marginTop: "5px" }}>
        {parsedProgressNote}
      </div>
      <div className={styles.modal_progress_note_footer}>
        {malnutRiskNumber.total.num > 0 && (
          <CopyToClipboard text={malnutritionCopy} onCopy={(text) => text}>
            <button className={buttonStyles.button}>
              Copy Malnutrition Risk
            </button>
          </CopyToClipboard>
        )}
      </div>
      <div style={{ marginTop: "1rem" }}>
        {foodAllergyText && (
          <CopyToClipboard text={foodAllergyText} onCopy={(text) => text}>
            <button className={buttonStyles.button}>
              Food Allergy Care Plan
            </button>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
};

export default ProgressModal;

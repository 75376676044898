import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../../utils/staticData";
import CopyToClipboard from "react-copy-to-clipboard";

//styles
import buttonStyles from "../../../../components/buttons/css/Button.module.css";
import styles from "../../../../css/UpperAssess.module.css";

export default function SupplementList({
  sup,
  prevDate,
  handleSupChange,
  handleRemove,
}) {
  return (
    <div className={styles.upper_assess_supplementlist}>
      <span>{`Supplement #${sup.supnum}`}</span>
      <input
        type="text"
        name="supplementList.supname"
        value={sup.supname}
        style={{ width: "20rem", marginLeft: "0.5rem", marginRight: "0.75rem" }}
        placeholder="Supplement Name and Amount"
        onChange={(e) => handleSupChange(e, sup.id)}
      />
      <span>Date Started:</span>
      <input
        type="date"
        name="supplementList.supdate"
        value={sup.supdate}
        style={{ marginLeft: "0.5rem", marginRight: "0.75rem" }}
        onChange={(e) => handleSupChange(e, sup.id)}
      />
      <span className={styles.list_addition_button}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.sup, sup.id)}
          style={{
            color: "rgb(241, 250, 238)",
            cursor: "pointer",
          }}
        />
      </span>
      <CopyToClipboard
        text={`${sup.supname} => started (${new Date(
          sup.supdate
        ).toLocaleDateString("en")})`}
        onCopy={(text) => text}>
        <button className={buttonStyles.button}>Copy</button>
      </CopyToClipboard>
      {new Date(sup.supdate) > new Date(prevDate) ? (
        <span style={{ color: "red", marginLeft: "5px" }}>Changed</span>
      ) : (
        <span></span>
      )}
    </div>
  );
}

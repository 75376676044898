import { configureStore } from "@reduxjs/toolkit";
import twoFaReducer from "./auth/twoFaSlice";
import authReducer from "./auth/authSlice";
import addLTDataReducer from "../features/standard/startpage/startPageSlice";
import { apiSlice } from "./api/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    twofa: twoFaReducer,
    auth: authReducer,
    addLT: addLTDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

import {
  gettheraDietChecker,
  getTextureChecker,
  vitaminRx,
  supplementRx,
  foodPrefsFunction,
  appetiteStimulationFunction,
  foodAllergiesFunction,
  malTitleFunction,
  totalCalcFunction,
} from "./helperFunctions.js";

import { THERADIETCHECKER, TEXTURECHECKER } from "./staticData.js";

///Recommendations///
export const nutritionRecsFunction = (data) => {
  const totalRecs = [];

  data.nutritionRecs.forEach((e, i) => {
    totalRecs.push([i + 1, e.value].join(". "));
  });

  if (totalRecs.length > 0) {
    return `Recommendations: ${totalRecs.join(", ")}`;
  } else {
    return "";
  }
};

///Malnutrition Change
export const malnutritionRiskChangeFunction = (data) => {
  const { num } = totalCalcFunction(data);
  const prevMalScore = Number(data.prevMalScore);
  const prevMalDate = new Date(data.prevMalDate).toLocaleDateString("en", {
    timeZone: "UTC",
  });

  let currentMnaValue;
  let previousMnaValue;

  if (num >= 12 && num <= 14) {
    currentMnaValue = "normal nutrition";
  } else if (num >= 8 && num <= 11) {
    currentMnaValue = "at risk of malnutrition";
  } else if (num > 0 && num <= 7) {
    currentMnaValue = "malnourished";
  } else {
    currentMnaValue = "";
  }

  if (prevMalScore >= 12 && prevMalScore <= 14) {
    previousMnaValue = "normal nutrition";
  } else if (prevMalScore >= 8 && prevMalScore <= 11) {
    previousMnaValue = "at risk of malnutrition";
  } else if (prevMalScore > 0 && prevMalScore <= 7) {
    previousMnaValue = "malnourished";
  } else {
    previousMnaValue = "";
  }

  const normal = "normal nutrition";
  const atRisk = "at risk of malnutrition";
  const malnourished = "malnourished";

  if (!currentMnaValue || !previousMnaValue) return "";
  if (currentMnaValue === previousMnaValue)
    return ` => Similar to last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === atRisk && currentMnaValue === normal)
    return ` => Improvement from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === atRisk && currentMnaValue === malnourished)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue}})`;
  if (previousMnaValue === malnourished && currentMnaValue === atRisk)
    return ` => Improvement from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === normal && currentMnaValue === atRisk)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === normal && currentMnaValue === malnourished)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === malnourished && currentMnaValue === normal)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
};

///Malnutrition Risk Screen///
export const malnutritionRiskFunction = (data) => {
  const malnutritionData = malTitleFunction(data);
  const malnutritionTotal = totalCalcFunction(data);

  const prevMalMnAScore = malnutritionRiskChangeFunction(data);

  let malDiagnosis;

  if (malnutritionTotal.num <= 11 && malnutritionTotal.num >= 8) {
    malDiagnosis = `Mini Nutritional Assessment: (${
      malnutritionTotal.num
    } of 14) is at risk for malnutrition secondary to ${malnutritionData.join(
      ", "
    )}${prevMalMnAScore}`;
  } else if (malnutritionTotal.num <= 7 && malnutritionTotal.num >= 0) {
    malDiagnosis = `Mini Nutritional Assessment: (${
      malnutritionTotal.num
    } of 14) has malnutrition secondary to ${malnutritionData.join(
      ", "
    )}${prevMalMnAScore}`;
  } else if (malnutritionTotal.num >= 12) {
    malDiagnosis = `Mini Nutritional Assessment: (${malnutritionTotal.num} of 14) Normal Nutrition Status${prevMalMnAScore}`;
  }

  return { malName: malDiagnosis, total: malnutritionTotal };
};

///Nutrition Rx Develpment///
export const nutritionRxFunction = (data) => {
  const theraDiet = gettheraDietChecker(data);
  const textureMod = getTextureChecker(data);
  const micronutrients = vitaminRx(data);
  const supplementList = supplementRx(data)
    .map((x) => x.name)
    .join(", ");
  const foodPrefs = foodPrefsFunction(data);

  const weightOrder = data.weightOrder;
  const adaptiveEquipment = data.adaptEquip;
  const selectMenu = data.selectMenu;
  const appetiteStimulant = appetiteStimulationFunction(data);
  const foodAllergies = foodAllergiesFunction(data).allergy.join("  ");
  const foodIntolerance = foodAllergiesFunction(data).intolerance.join("  ");

  const nutritionRxa = [];
  const nutritionRxb = [];

  //diet / texture changes
  const dietCheck = THERADIETCHECKER.some(
    (diet) => diet.toLowerCase() === data.theraDiet.toLowerCase()
  );

  const textureCheck = TEXTURECHECKER.some(
    (texture) => texture.toLowerCase() === data.texture.toLowerCase()
  );

  if (dietCheck && data.theraDiet && textureCheck) {
    nutritionRxa.push(`${data.theraDiet}`);
  }

  if (!dietCheck && data.theraDiet && textureCheck) {
    nutritionRxa.push(`On ${data.theraDiet} secondary to ${theraDiet}`);
  }

  if (!dietCheck && data.theraDiet && !textureCheck && data.texture) {
    nutritionRxa.push(
      `On ${data.theraDiet} secondary to ${theraDiet}, ${textureMod}`
    );
  }

  if (dietCheck && !textureCheck && data.texture) {
    nutritionRxa.push(textureMod.charAt(0).toUpperCase() + textureMod.slice(1));
  }

  // micronutrients
  if (micronutrients) nutritionRxa.push(micronutrients);
  // supplements
  if (supplementList) nutritionRxa.push(supplementList);
  // food prefs
  if (foodPrefs) nutritionRxa.push(foodPrefs);
  // weight order
  if (weightOrder) nutritionRxa.push(weightOrder);
  // adaptive equipment
  if (adaptiveEquipment) nutritionRxa.push(adaptiveEquipment);
  // appetite stimulant
  if (appetiteStimulant) nutritionRxa.push(appetiteStimulant);
  // select menu
  if (selectMenu) nutritionRxa.push(`Select Menu`);
  //food allergies/intolerances
  if (foodAllergies && foodIntolerance) {
    nutritionRxa.push(
      `Food Allergy to ${foodAllergies} and Intolerance to ${foodIntolerance} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  } else if (foodAllergies) {
    nutritionRxa.push(
      `Food Allergy to ${foodAllergies} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  } else if (foodIntolerance) {
    nutritionRxa.push(
      `Food Intolerance to ${foodIntolerance} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  }

  // nutrition Rx setup
  nutritionRxa.forEach((e, i) => {
    nutritionRxb.push([i + 1, e].join(". "));
  });

  if (nutritionRxb.length > 0)
    return `Nutrition Rx: ${nutritionRxb.join(", ")}`;
};

import { React, useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";

//components
import FoodAllergies from "../assessments/upper-assess/FoodAllergies";
import FoodIntolerance from "../assessments/upper-assess/FoodIntolerance";
import InputFields from "../assessments/middle-assess/InputFields";
import StandardButton from "../../../components/buttons/StandardButton";
import SupplementList from "../assessments/upper-assess/SupplementList";
import LongTermNutritionDiagnosis from "./LongTermNutritionDiagnosis";

// named imports
import {
  NESTED_IDS,
  hardCodeArrays as setterArrays,
} from "../../../utils/staticData";
import {
  useAddNewLongTermItemMutation,
  useUpdateLongTermItemMutation,
} from "../../../app/api/longTermApiSlice";

//library imports
import { faSquarePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from "uuid";

//styles
import styles from "../../../css/LongTermLists.module.css";
import LTButton from "../../../components/buttons/LTButton";

const LongTermResInput = ({ data, editId, handleCloseToggle, buttonView }) => {
  const [loader, setLoader] = useState(false);
  const [supNumber, setSupNumber] = useState(1);
  const [allergyNumber, setAllergyNumber] = useState(1);
  const [intoleranceNumber, setIntoleranceNumber] = useState(1);
  const [fieldNumber, setFieldNumber] = useState(1);
  const [count, setCount] = useState(1);
  const [clear, setClear] = useState(false);

  const { location } = useAuth();

  const [toggle, setToggle] = useState({
    allergy: false,
    intolerance: false,
    supplement: false,
  });

  const [filteredData] = data?.filter((item) => item._id === editId);

  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    age: "",
    sex: "",
    pmhx: "",
    upperDent: "",
    lowerDent: "",
    foodAllergy: [],
    foodIntolerance: [],
    inputFields: [],
    supplementList: [],
    adaptEquip: "",
    ambulation: "",
    eatSelfPerform: "",
    eatSupportPerform: "",
    portionSize: "",
    location: location,
    nutritionDiagnosis: [],
  });

  const [createItem, { isSuccess: createSuccess }] =
    useAddNewLongTermItemMutation();

  const [updateItem, { isSuccess: updateSuccess }] =
    useUpdateLongTermItemMutation();

  useEffect(() => {
    if (editId) {
      setInputs({
        firstName: filteredData.firstName,
        lastName: filteredData.lastName,
        age: filteredData.age,
        sex: filteredData.sex,
        pmhx: filteredData.pmhx,
        upperDent: filteredData.upperDent,
        lowerDent: filteredData.lowerDent,
        foodAllergy: filteredData.foodAllergy,
        foodIntolerance: filteredData.foodIntolerance,
        inputFields: filteredData.inputFields,
        supplementList: filteredData.supplementList,
        adaptEquip: filteredData.adaptEquip,
        ambulation: filteredData.ambulation,
        eatSelfPerform: filteredData.eatSelfPerform,
        eatSupportPerform: filteredData.eatSupportPerform,
        nutritionDiagnosis: filteredData.nutritionDiagnosis,
        location: filteredData.location,
        portionSize: filteredData.portionSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  useEffect(() => {
    if (createSuccess || clear || updateSuccess) {
      setInputs({
        firstName: "",
        lastName: "",
        age: "",
        sex: "",
        pmhx: "",
        upperDent: "",
        lowerDent: "",
        foodAllergy: [],
        foodIntolerance: [],
        inputFields: [],
        supplementList: [],
        adaptEquip: "",
        ambulation: "",
        eatSelfPerform: "",
        eatSupportPerform: "",
        nutritionDiagnosis: [],
        location: location,
        portionSize: "",
      });
      setClear(false);
      setSupNumber(1);
      setAllergyNumber(1);
      setIntoleranceNumber(1);
      setFieldNumber(1);
    }
  }, [createSuccess, clear, updateSuccess, location]);

  const handleClear = () => {
    setClear(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNestedChange = (e, id) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "supplementList":
        const mappedSupInputs = inputs.supplementList.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );

        setInputs({ ...inputs, [parentKey]: mappedSupInputs });
        break;
      case "foodAllergy":
        const mappedAllergyInputs = inputs.foodAllergy.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );

        setInputs({ ...inputs, [parentKey]: mappedAllergyInputs });
        break;
      case "foodIntolerance":
        const mappedIntoleranceInputs = inputs.foodIntolerance.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedIntoleranceInputs });
        break;
      case "inputFields":
        const mappedInputFieldsInputs = inputs.inputFields.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedInputFieldsInputs });
        break;
      case "nutritionDiagnosis":
        const mappedNutritionDiagnosisInputs = inputs.nutritionDiagnosis.map(
          (item) => (item.id === id ? { ...item, [childKey]: value } : item)
        );
        setInputs({ ...inputs, [parentKey]: mappedNutritionDiagnosisInputs });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };

  const handleRemove = (name, id) => {
    switch (name) {
      case "supplementList":
        setSupNumber((prev) => prev - 1);
        const mappedSupInputs = inputs.supplementList.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, supplementList: mappedSupInputs });
        break;
      case "foodAllergy":
        setAllergyNumber((prev) => prev - 1);
        const mappedAllergyInputs = inputs.foodAllergy.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, foodAllergy: mappedAllergyInputs });
        break;
      case "foodIntolerance":
        setIntoleranceNumber((prev) => prev - 1);
        const mappedIntoleranceInputs = inputs.foodIntolerance.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, foodIntolerance: mappedIntoleranceInputs });
        break;
      case "inputFields":
        setFieldNumber((prev) => prev - 1);
        const mappedInputFieldsInputs = inputs.inputFields.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, inputFields: mappedInputFieldsInputs });
        break;
      case "nutritionDiagnosis":
        setCount((prev) => prev - 1);
        const mappedNutritionDiagnosis = inputs.nutritionDiagnosis.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, nutritionDiagnosis: mappedNutritionDiagnosis });
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  const handleAddition = (e) => {
    const { name } = e.target;

    switch (name) {
      case "inputFields":
        if (fieldNumber <= 4) {
          setFieldNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            inputFields: [
              ...inputs.inputFields,
              { id: uuid(), fieldnum: fieldNumber, value: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "nutritionDiagnosis":
        if (count <= 3) {
          setCount((prev) => prev + 1);
          setInputs({
            ...inputs,
            nutritionDiagnosis: [
              ...inputs.nutritionDiagnosis,
              {
                id: uuid(),
                num: count,
                problem: "",
                etiology: "",
                symptom: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  const handleIconAdd = (id) => {
    switch (id) {
      case "supplementList":
        if (supNumber <= 3) {
          setSupNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            supplementList: [
              ...inputs.supplementList,
              { id: uuid(), supnum: supNumber, supname: "", supdate: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "foodAllergy":
        if (allergyNumber <= 3) {
          setAllergyNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            foodAllergy: [
              ...inputs.foodAllergy,
              { id: uuid(), allergynum: allergyNumber, allergyname: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "foodIntolerance":
        if (intoleranceNumber <= 3) {
          setIntoleranceNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            foodIntolerance: [
              ...inputs.foodIntolerance,
              {
                id: uuid(),
                intolerancenum: intoleranceNumber,
                intolerancename: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      default:
        console.log(`no ${id} found`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await createItem(inputs);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const handleUpdate = async (e) => {
    setLoader(true);
    await updateItem(inputs);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  return (
    <div className={styles.lt_outer_container}>
      <div className={styles.lt_inner_container}>
        <div className={styles.lt_top_row}>
          <h4>Long Term Resident Input</h4>
          <FontAwesomeIcon
            icon={faXmark}
            size="md"
            onClick={handleCloseToggle}
            style={{
              paddingRight: "10px",
              paddingBottom: "5px",
              cursor: "pointer",
            }}
          />
        </div>
        <div className={styles.lt_second_row}>
          <div>
            <span> Last Name: </span>
            <input
              type="text"
              name="lastName"
              value={inputs.lastName}
              onChange={handleChange}
            />
          </div>
          <div>
            <span> First Name: </span>
            <input
              type="text"
              name="firstName"
              value={inputs.firstName}
              onChange={handleChange}
            />
          </div>
          <div>
            <span> DOB: </span>
            <input
              type="date"
              name="age"
              value={inputs.age}
              onChange={handleChange}
            />
          </div>
          <div>
            <span style={{ marginLeft: "0.5rem" }}>Sex:</span>
            <select name="sex" value={inputs.sex} onChange={handleChange}>
              <option value=""></option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div>
            <span style={{ marginRight: "0.5rem" }}>Location:</span>
            <span>{inputs.location.toUpperCase()}</span>
          </div>
        </div>
        <div className={styles.lt_inputs_pmhx}>
          <span> PMHx: </span>
          <textarea
            name="pmhx"
            rows="4"
            cols="40"
            value={inputs.pmhx}
            onChange={handleChange}
          />
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <span> Dentition: </span>
          <input
            type="text"
            list="upperdent"
            name="upperDent"
            value={inputs.upperDent}
            placeholder="Upper Dentition"
            onChange={handleChange}
          />{" "}
          <datalist id="upperdent">
            {setterArrays.upperdent.map((d, i) => (
              <option key={i} value={d} />
            ))}
          </datalist>
          <input
            type="text"
            list="lowerdent"
            name="lowerDent"
            value={inputs.lowerDent}
            placeholder="Lower Dentition"
            onChange={handleChange}
          />
          <datalist id="lowerdent">
            {setterArrays.lowerdent.map((d, i) => (
              <option key={i} value={d} />
            ))}
          </datalist>
        </div>
        <div className={styles.lt_allergy_list_question}>
          <span>
            Does the Patient/Resident have <strong>Food Allergies</strong>?
          </span>
          <span>Yes/No:</span>
          <input
            name="foodAllergy"
            checked={toggle.allergy}
            type="checkbox"
            onChange={(e) =>
              setToggle((prev) => ({ ...prev, allergy: e.target.checked }))
            }
          />
          <span>
            <strong>Food Intolerances</strong>?
          </span>
          <span>Yes/No:</span>

          <input
            name="foodIntolerance"
            type="checkbox"
            checked={toggle.intolerance}
            onChange={(e) =>
              setToggle((prev) => ({
                ...prev,
                intolerance: e.target.checked,
              }))
            }
          />
          <span>Portion Size: </span>
          <select
            name="portionSize"
            value={inputs.portionSize}
            onChange={handleChange}>
            {setterArrays.portionSize.map((p, i) => (
              <option key={i} value={p}>
                {p}
              </option>
            ))}
          </select>
        </div>
        {toggle.allergy && (
          <div className={styles.lt_allergy_list}>
            <span style={{ paddingRight: "10px" }}>Food Allergies:</span>
            {inputs.foodAllergy.map((allergy) => (
              <FoodAllergies
                key={allergy.id}
                allergy={allergy}
                handleNestedChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
            <span>
              <FontAwesomeIcon
                icon={faSquarePlus}
                size="lg"
                id="supplementList"
                onClick={() => handleIconAdd(NESTED_IDS.allergy)}
                style={{
                  color: "#6e6d70",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        )}
        {toggle.intolerance && (
          <div style={{ paddingBottom: "10px" }}>
            <span style={{ paddingRight: "10px" }}>Food Intolerances:</span>
            {inputs.foodIntolerance.map((intol) => (
              <FoodIntolerance
                key={intol.id}
                intol={intol}
                handleNestedChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
            <span className={styles.list_addition_button}>
              <FontAwesomeIcon
                icon={faSquarePlus}
                size="lg"
                id="supplementList"
                onClick={() => handleIconAdd(NESTED_IDS.intolerance)}
                style={{
                  color: "#6e6d70",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        )}
        <div className={styles.lt_inputs_adapt}>
          <span> Adaptive Equipment: </span>
          <textarea
            name="adaptEquip"
            value={inputs.adaptEquip}
            rows="2"
            cols="30"
            onChange={handleChange}
          />
        </div>
        <div>
          <span>Food Preferences:</span>

          <div>
            <div className={styles.lt_input_food_prefs}>
              {inputs.inputFields.length === 0 && (
                <span>Click the button below to add a field</span>
              )}
              {inputs.inputFields.map((item) => (
                <InputFields
                  item={item}
                  key={item.id}
                  handleNestedChange={handleNestedChange}
                  handleRemove={handleRemove}
                />
              ))}
              <StandardButton
                event={handleAddition}
                name="inputFields"
                title="Add New Preferences"
                style={{ paddingTop: "6px" }}
              />
            </div>
            <div className={styles.lt_outer_sup_list}>
              <span>Is the Patient/Resident on a Supplement?</span>
              <label>
                {" "}
                Yes/No:
                <input
                  type="checkbox"
                  checked={toggle.supplement}
                  onChange={(e) =>
                    setToggle((prev) => ({
                      ...prev,
                      supplement: e.target.checked,
                    }))
                  }
                />
              </label>
              {toggle.supplement && (
                <div>
                  <span style={{ paddingRight: "10px" }}>Supplements</span>
                  <span className={styles.list_addition_button}>
                    <FontAwesomeIcon
                      icon={faSquarePlus}
                      size="lg"
                      id="supplementList"
                      onClick={(e) => handleAddition(NESTED_IDS.sup)}
                      style={{
                        color: "rgb(241, 250, 238)",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </div>
              )}
            </div>

            {inputs.supplementList.map((sup) => (
              <SupplementList
                key={sup.id}
                sup={sup}
                handleSupChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
          </div>
          <div className={styles.lt_lower_assess}>
            <span className={styles.adapt_equip_item_c}>Ambulation:</span>
            <select
              name="ambulation"
              className={styles.adapt_equip_item_d}
              value={inputs.ambulation}
              onChange={handleChange}>
              <option value=""></option>
              <option value="ambulatory">Ambulatory</option>
              <option value="walker/cane/crutch">Walker/cane/crutch</option>
              <option value="wheelchair">Wheelchair</option>
              <option value="paces/wanders">Paces/Wanders</option>
              <option value="chair/bedfast">Chair/Bedfast</option>
              <option value="scooter">Scooter</option>
            </select>
            <span style={{ marginLeft: "1rem" }}>
              Eating (Support Provided):
            </span>
            <select
              name="eatSupportPerform"
              value={inputs.eatSupportPerform}
              onChange={handleChange}>
              {setterArrays.eatSupportPerform.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
            <span style={{ marginLeft: "1rem" }}>
              Eating (Self Performance):
            </span>
            <select
              name="eatSelfPerform"
              value={inputs.eatSelfPerform}
              onChange={handleChange}>
              {setterArrays.eatSelfPerformance.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.data_entry_nutrition_diagnosis_title}>
            <h4 className={styles.data_entry_title}>Nutrition Diagnosis</h4>{" "}
            <StandardButton
              event={handleAddition}
              name="nutritionDiagnosis"
              title="Add New Diagnosis"
            />
          </div>
          <div className={styles.nutrition_diagnosis_container}>
            {inputs.nutritionDiagnosis?.map((item) => (
              <LongTermNutritionDiagnosis
                key={item.id}
                item={item}
                handleNestedChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
          </div>
        </div>
        <div className={styles.button_row}>
          {buttonView.save && (
            <LTButton title="Save" loader={loader} event={handleSubmit} />
          )}
          {buttonView.update && (
            <LTButton
              title="Update"
              loader={loader}
              event={handleUpdate}
              name={editId}
            />
          )}
          <button type="button" className={styles.button} onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default LongTermResInput;

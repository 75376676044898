import { NavLink } from "react-router-dom";
import { useSendLogoutMutation } from "../../app/api/authAPISlice";
import styles from "./css/Nav.module.css";
import logo from "../../images/apple.png";
import {
  faGear,
  faRightFromBracket,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Nav() {
  const [sendLogout] = useSendLogoutMutation();

  return (
    <div className={styles.nav_container}>
      <div className={styles.nav_left}>
        <img src={logo} alt="Logo" className={styles.nav_logo} />
        <h3 className={styles.nav_name}>Nutrition Toolbox</h3>
      </div>
      <div className={styles.nav_right}>
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            isActive ? `${styles.active}` : `${styles.nav_upper_links}`
          }>
          <FontAwesomeIcon icon={faHouse} color="white" />
        </NavLink>
        <NavLink to="/" onClick={sendLogout}>
          <FontAwesomeIcon icon={faRightFromBracket} color="white" />
        </NavLink>
        <NavLink to="/settings">
          <FontAwesomeIcon icon={faGear} color="white" />
        </NavLink>
      </div>
    </div>
  );
}

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../../utils/staticData";

const FoodIntolerance = ({ intol, handleNestedChange, handleRemove }) => {
  return (
    <>
      <input
        type="text"
        name="foodIntolerance.intolerancename"
        placeholder={`Food Intolerance #${intol.intolerancenum}`}
        value={intol.intolerancename}
        onChange={(e) => handleNestedChange(e, intol.id)}
        style={{ width: "9rem", marginLeft: "10px", marginRight: "6px" }}
      />
      <span style={{ marginRight: "5px" }}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.intolerance, intol.id)}
          style={{
            color: "rgb(241, 250, 238)",
            cursor: "pointer",
          }}
        />
      </span>
    </>
  );
};

export default FoodIntolerance;

import styles from "./css/Button.module.css";

const StandardButton = ({ event, name, title, style }) => {
  return (
    <div style={style}>
      <button
        type="button"
        name={name}
        className={styles.button}
        onClick={event}>
        {title}
      </button>
    </div>
  );
};

export default StandardButton;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../../utils/staticData";

const InputFields = ({ item, handleNestedChange, handleRemove }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <textarea
        type="text"
        name="inputFields.value"
        value={item.value}
        cols={30}
        rows={3}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span style={{ marginLeft: "5px" }}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.inputfields, item.id)}
          style={{
            color: "rgb(241, 250, 238)",
            cursor: "pointer",
          }}
        />
      </span>
    </div>
  );
};

export default InputFields;

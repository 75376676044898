import { useState } from "react";
import { v4 as uuid } from "uuid";

//components
import MiddleAssess from "./middle-assess/MiddleAssess.jsx";
import SaveButton from "../../../components/buttons/SaveButton.js";
import UpperAssess from "./upper-assess/UpperAssess.jsx";
import LowerAssess from "./lower-assess/LowerAssess.jsx";

//styles
import styles from "../../../css/AssessmentForm.module.css";

const AssessmentForm = ({
  data,
  handleEnteralToggle,
  handleParenteralToggle,
}) => {
  const [supNumber, setSupNumber] = useState(1);
  const [allergyNumber, setAllergyNumber] = useState(1);
  const [intoleranceNumber, setIntoleranceNumber] = useState(1);
  const [fieldNumber, setFieldNumber] = useState(1);
  const [count, setCount] = useState(1);
  const [microFieldNumber, setMicroFieldNumber] = useState(1);

  const [inputs, setInputs] = useState({
    id: data._id,
    assessType: data.assessType,
    ltsave: data.ltsave,
    age: data.age,
    admitType: data.admitType,
    admitLocal: data.admitLocal,
    dietPrior: data.dietPrior,
    clientType: data.clientType,
    lastName: data.lastName,
    firstName: data.firstName,
    enteralBoolean: data.enteralBoolean,
    parenteralBoolean: data.parenteralBoolean,
    room: data.room,
    sex: data.sex,
    theraDiet: data.theraDiet,
    prevAssessType: data.prevAssessType,
    prevDate: data.prevDate,
    texture: data.texture,
    liquids: data.liquids,
    foodIntake: data.foodIntake,
    supIntake: data.supIntake,
    supplementList: data.supplementList,
    eatSelfPerform: data.eatSelfPerform,
    eatSupportPerform: data.eatSupportPerform,
    portionSize: data.portionSize,
    foodAllergy: data.foodAllergy,
    foodIntolerance: data.foodIntolerance,
    upperDent: data.upperDent,
    lowerDent: data.lowerDent,
    adaptEquip: data.adaptEquip,
    ambulation: data.ambulation,
    dietstartdate: data.dietstartdate,
    ivfluids: data.ivfluids,
    ht: data.ht,
    wt: data.wt,
    ibw: data.ibw,
    ubwLow: data.ubwLow,
    ubwHigh: data.ubwHigh,
    ubwMonths: data.ubwMonths,
    weightOrder: data.weightOrder,
    currwt: data.currwt,
    currWtDate: data.currWtDate,
    wtChange: data.wtChange,
    wtchangetype: data.wtchangetype,
    actFactor: data.actFactor,
    injType: data.injType,
    injFactor: data.injFactor,
    wttype: data.wttype,
    calneedslow: data.calneedslow,
    calneedshigh: data.calneedshigh,
    proneedslow: data.proneedslow,
    proneedshigh: data.proneedshigh,
    fluidneedslow: data.fluidneedslow,
    fluidneedshigh: data.fluidneedshigh,
    labs: data.labs,
    inputFields: data.inputFields,
    selectMenu: data.selectMenu,
    pmhx: data.pmhx,
    meds: data.meds,
    skin: data.skin,
    micronutrients: data.micronutrients,
    micronutrientInputs: data.micronutrientInputs,
    appetiteStim: data.appetiteStim,
  });

  /////////////Event Handlers/////////////////////
  /// main handler to update state
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;

    if (
      name === "selectMenu" ||
      name === "enteralBoolean" ||
      name === "parenteralBoolean"
    ) {
      setInputs((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setInputs((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  /// update nested components to state
  const handleNestedChange = (e, id) => {
    const { name, value, checked } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "supplementList":
        const mappedSupInputs = inputs.supplementList.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );

        setInputs({ ...inputs, [parentKey]: mappedSupInputs });
        break;
      case "foodAllergy":
        const mappedAllergyInputs = inputs.foodAllergy.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );

        setInputs({ ...inputs, [parentKey]: mappedAllergyInputs });
        break;
      case "foodIntolerance":
        const mappedIntoleranceInputs = inputs.foodIntolerance.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedIntoleranceInputs });
        break;
      case "wtChange":
        if (childKey === "toggle") {
          const mappedWtChangeInputsChecked = inputs.wtChange.map((item) =>
            item.id === id ? { ...item, toggle: checked } : item
          );
          setInputs({ ...inputs, [parentKey]: mappedWtChangeInputsChecked });
        } else {
          const mappedWtChangeInputsValues = inputs.wtChange.map((item) =>
            item.id === id ? { ...item, [childKey]: value } : item
          );
          setInputs({ ...inputs, [parentKey]: mappedWtChangeInputsValues });
        }
        break;
      case "labs":
        const mappedLabsInputs = inputs.labs.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedLabsInputs });
        break;
      case "inputFields":
        const mappedInputFieldsInputs = inputs.inputFields.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedInputFieldsInputs });
        break;
      case "micronutrients":
        const mappedMicronutrientInputs = inputs.micronutrients.map((item) =>
          item.id === id ? { ...item, [childKey]: checked } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedMicronutrientInputs });
        break;
      case "micronutrientInputs":
        const mappedMicronutrientsInputs = inputs.micronutrientInputs.map(
          (item) => (item.id === id ? { ...item, [childKey]: value } : item)
        );
        setInputs({ ...inputs, [parentKey]: mappedMicronutrientsInputs });
        break;
      case "appetiteStim":
        const mappedAppetiteStimInputs = inputs.appetiteStim.map((item) =>
          item.id === id ? { ...item, [childKey]: checked } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedAppetiteStimInputs });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };
  /// update nested lab component
  const handleNestedLabChange = (id, lab) => {
    const mappedLabsInputs = inputs.labs.map((item) =>
      item.id === id ? { ...item, range: lab } : item
    );
    setInputs({ ...inputs, labs: mappedLabsInputs });
  };

  /// add additional input fields
  const handleAddition = (e) => {
    const { name } = e.target;

    switch (name) {
      case "inputFields":
        if (fieldNumber <= 3) {
          setFieldNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            inputFields: [
              ...inputs.inputFields,
              { id: uuid(), fieldnum: fieldNumber, value: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "nutritionDiagnosis":
        if (count <= 3) {
          setCount((prev) => prev + 1);
          setInputs({
            ...inputs,
            nutritionDiagnosis: [
              ...inputs.nutritionDiagnosis,
              {
                id: uuid(),
                num: count,
                problem: "",
                etiology: "",
                symptom: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      case "micronutrientInputs":
        if (microFieldNumber <= 2) {
          setMicroFieldNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            micronutrientInputs: [
              ...inputs.micronutrientInputs,
              { id: uuid(), micronum: 0, value: "" },
            ],
          });
        } else {
          return;
        }

        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  const handleIconAdd = (id) => {
    switch (id) {
      case "supplementList":
        if (supNumber <= 3) {
          setSupNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            supplementList: [
              ...inputs.supplementList,
              { id: uuid(), supnum: supNumber, supname: "", supdate: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "foodAllergy":
        if (allergyNumber <= 3) {
          setAllergyNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            foodAllergy: [
              ...inputs.foodAllergy,
              { id: uuid(), allergynum: allergyNumber, allergyname: "" },
            ],
          });
        } else {
          return;
        }
        break;
      case "foodIntolerance":
        if (intoleranceNumber <= 3) {
          setIntoleranceNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            foodIntolerance: [
              ...inputs.foodIntolerance,
              {
                id: uuid(),
                intolerancenum: intoleranceNumber,
                intolerancename: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      default:
        console.log(`no ${id} found`);
    }
  };

  /// Remove input fields
  const handleRemove = (name, id) => {
    switch (name) {
      case "supplementList":
        setSupNumber((prev) => prev - 1);
        const mappedSupInputs = inputs.supplementList.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, supplementList: mappedSupInputs });
        break;
      case "foodAllergy":
        setAllergyNumber((prev) => prev - 1);
        const mappedAllergyInputs = inputs.foodAllergy.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, foodAllergy: mappedAllergyInputs });
        break;
      case "foodIntolerance":
        setIntoleranceNumber((prev) => prev - 1);
        const mappedIntoleranceInputs = inputs.foodIntolerance.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, foodIntolerance: mappedIntoleranceInputs });
        break;
      case "inputFields":
        setFieldNumber((prev) => prev - 1);
        const mappedInputFieldsInputs = inputs.inputFields.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, inputFields: mappedInputFieldsInputs });
        break;
      case "micronutrientInputs":
        setMicroFieldNumber((prev) => prev - 1);
        const mappedMicronutrientsInputs = inputs.micronutrientInputs.filter(
          (item) => item.id !== id
        );
        setInputs({
          ...inputs,
          micronutrientInputs: mappedMicronutrientsInputs,
        });
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  return (
    <div>
      <UpperAssess
        ard={data.date}
        inputs={inputs}
        handleChange={handleChange}
        handleNestedChange={handleNestedChange}
        handleRemove={handleRemove}
        handleIconAdd={handleIconAdd}
        handleEnteralToggle={handleEnteralToggle}
        handleParenteralToggle={handleParenteralToggle}
      />
      <MiddleAssess
        inputs={inputs}
        handleChange={handleChange}
        handleNestedChange={handleNestedChange}
        handleNestedLabChange={handleNestedLabChange}
        handleRemove={handleRemove}
        handleAddition={handleAddition}
      />
      <LowerAssess
        inputs={inputs}
        handleChange={handleChange}
        handleNestedChange={handleNestedChange}
        handleRemove={handleRemove}
        handleAddition={handleAddition}
      />
      <div className={styles.save_button_bar}>
        <SaveButton title="Save" inputs={inputs} data={data} />
      </div>
    </div>
  );
};

export default AssessmentForm;

//hooks
import useMobile from "../hooks/useMobile";

//components
import TwoFaStandard from "../features/standard/twofa/TwoFaStandard";
import TwoFaMobile from "../features/mobile/TwoFA/TwoFaMobile";
import AppLogout from "../app/auth/AppLogout";

function TwoFA() {
  const isMobile = useMobile();

  return !isMobile ? (
    <AppLogout>
      <TwoFaStandard />
    </AppLogout>
  ) : (
    <AppLogout>
      <TwoFaMobile />
    </AppLogout>
  );
}

export default TwoFA;

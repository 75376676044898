import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyToClipboard from "react-copy-to-clipboard";

//styles
import styles from "../../../../css/DataEntry.module.css";
import buttonStyles from "../../../../components/buttons/css/Button.module.css";

export default function DataEntryLabs({ lab }) {
  const labDate = new Date(lab.date).toLocaleDateString("en-US", {
    timeZone: "UTC",
  });

  const labText = `${lab.value} (${lab.date && labDate})`;

  let arrow;

  if (lab.range === "high") {
    arrow = (
      <FontAwesomeIcon
        icon={faArrowUp}
        size="sm"
        style={{ color: "rgb(241, 250, 238)", marginLeft: "0.3rem" }}
      />
    );
  }
  if (lab.range === "very high") {
    arrow = (
      <>
        <FontAwesomeIcon
          icon={faArrowUp}
          size="sm"
          style={{ color: "rgb(241, 250, 238)", marginLeft: "0.3rem" }}
        />
        <FontAwesomeIcon
          icon={faArrowUp}
          size="sm"
          style={{ color: "rgb(241, 250, 238)" }}
        />
      </>
    );
  }
  if (lab.range === "low") {
    arrow = (
      <>
        <FontAwesomeIcon
          icon={faArrowDown}
          size="sm"
          style={{
            color: "rgb(241, 250, 238)",
            marginLeft: "0.3rem",
            marginRight: "0.3rem",
          }}
        />
      </>
    );
  }
  if (lab.range === "very low") {
    arrow = (
      <>
        {" "}
        <FontAwesomeIcon
          icon={faArrowDown}
          size="sm"
          style={{ color: "rgb(241, 250, 238)", marginLeft: "0.3rem" }}
        />
        <FontAwesomeIcon
          icon={faArrowDown}
          size="sm"
          style={{ color: "rgb(241, 250, 238)", marginLeft: "0.3rem" }}
        />
      </>
    );
  }

  let content;

  if (lab.value && lab.date) {
    content = (
      <div className={styles.labs_div}>
        <span>{lab.name}:</span>
        <span>{labText}</span>
        <span>{arrow}</span>
        <CopyToClipboard text={labText} onCopy={(text) => text}>
          <button className={buttonStyles.button}>Copy</button>
        </CopyToClipboard>
      </div>
    );
  } else {
    content = <div></div>;
  }

  return content;
}

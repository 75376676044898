import React, { useEffect, useState } from "react";
import { labCalc } from "../../../../utils/helperFunctions";

//styles
import groupStyles from "../../../../css/AssessmentForm.module.css";

const Labs = ({ item, inputs, handleNestedChange, handleNestedLabChange }) => {
  const [labChange, setLabChange] = useState("");

  useEffect(() => {
    let labCheck;
    const delayFunction = setTimeout(() => {
      labCheck = labCalc(item.name, Number(item.value), inputs.sex);
      setLabChange(labCheck);
    }, 500);

    return () => clearTimeout(delayFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.value]);

  useEffect(() => {
    handleNestedLabChange(item.id, labChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labChange]);

  return (
    <>
      <span>{item.name}:</span>
      <input
        type="text"
        className={groupStyles.center_text}
        name="labs.value"
        value={item.value}
        style={{ width: "2.5rem" }}
        onChange={(e) => handleNestedChange(e, item.id, labChange)}
      />
      <div>
        <input
          type="date"
          name="labs.date"
          value={item.date}
          onChange={(e) => handleNestedChange(e, item.id)}
        />
        <span style={{ marginLeft: "5px" }}>{labChange}</span>
      </div>
    </>
  );
};

export default Labs;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../utils/staticData";

//styles
import "../../../css/StartPage.css";
import globalStyles from "../../../css/globalStyles.module.css";

function InputFieldsMobile({ item, handleNestedChange, handleRemove }) {
  return (
    <div className="food_prefs_rows_mobile">
      <textarea
        name="inputFields.value"
        value={item.value}
        cols={40}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span className="list_addition_button">
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.inputfields, item.id)}
          className={globalStyles.font_awesome_buttons}
        />
      </span>
    </div>
  );
}

export default InputFieldsMobile;

//styles
import styles from "./css/Button.module.css";

function LTButton({ title, event, loader, name }) {
  return (
    <div className={styles.save_button_container}>
      {!loader ? (
        <button
          type="button"
          name={name}
          className={styles.button}
          onClick={event}>
          {title}
        </button>
      ) : (
        <span className={styles.loader}></span>
      )}
    </div>
  );
}

export default LTButton;

import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerify2faMutation } from "../../../app/api/authAPISlice";
import styles from "../../../css/TwoFA.module.css";
import { setCredentials } from "../../../app/auth/authSlice";

const TwoFaMobile = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [token, setToken] = useState("");

  const [verify2fa] = useVerify2faMutation();

  const handleTokenInput = (e) => {
    const { value } = e.target;

    if (value.match(/^[0-9]*$/)) {
      setToken(value);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const res = await verify2fa({
        userId: data,
        token,
      }).unwrap();
      const accessToken = res.accessToken;
      dispatch(setCredentials({ accessToken: accessToken }));
      navigate("/dashboard");
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 404) {
        setErrMsg("User does not exist");
      } else if (err.status === 401) {
        setErrMsg("Unathorized");
      }
    }
  };

  return (
    <div className={styles.fa_wrapper_outer}>
      <div className={styles.fa_wrapper_inner_mobile}>
        <div>
          <h3 className={styles.fa_h3_mobile}>Secondary Validation</h3>
          <input
            className={styles.fa_input_mobile}
            type="text"
            value={token}
            maxLength={6}
            onChange={handleTokenInput}
          />
          {errMsg && <p className={styles.fa_errmsg}>{errMsg}</p>}
        </div>
        <div className={styles.fa_button_row_mobile}>
          <button className={styles.fa_button_mobile} onClick={handleVerify}>
            Send
          </button>
          <button
            className={styles.fa_button_clear}
            onClick={() => setToken("")}>
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default TwoFaMobile;

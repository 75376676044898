import { useState } from "react";

import styles from "../../../css/Weights.module.css";

function WeightChart() {
  const [resident, setResident] = useState([
    {
      lastName: "Ainsworth",
      firstName: "Pamela",
      room: "110-B",
      ht: "64",
      wts: [""],
    },
  ]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className={styles.table_container}>
      <table className={styles.weight_table}>
        <thead>
          <th>Resident Name</th>
          <th>Room Number</th>
          <th>Height (inches)</th>
          <th>BMI</th>
          <th>UBW</th>
          <th>% UBW</th>
        </thead>
        <tbody>
          {resident.map((res) => (
            <tr>
              <td>
                {res.lastName}, {res.firstName}
              </td>
              <td>{res.room}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.month_scroll}>
        <table className={styles.weight_table}>
          <thead>
            {months.map((month) => (
              <th>{month}</th>
            ))}
            <td>{months[0]}</td>
          </thead>
          <tbody>
            <td>145</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WeightChart;

import CopyButton from "../../../../components/buttons/CopyButton";
import styles from "../../../../css/LowerAssess.module.scss";

export default function MedInputs({ title, name, value, handleChange }) {
  return (
    <>
      <div className={styles.lower_assess_medinput}>
        <span>{title}</span>
        {name !== "pmhx" && <CopyButton value={value} />}
      </div>
      <textarea name={name} value={value} onChange={handleChange}></textarea>
    </>
  );
}

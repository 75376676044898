import Nav from "../components/navigation/Nav.jsx";
import NavMobile from "../components/navigation/NavMobile.jsx";
import ListMaker from "../features/standard/startpage/ListMaker.jsx";
import ToDoMaker from "../features/standard/startpage/ToDoMaker.jsx";
import LowerNav from "../components/navigation/LowerNav.jsx";

//mobile components
import TooSeeListMobile from "../features/mobile/startpage/TooSeeListMobile.jsx";
import TooSeeListMobileTitle from "../features/mobile/startpage/TooSeeListMobileTitle.jsx";

//hooks
import useIsMobile from "../hooks/useMobile.jsx";

//styles
import "../css/StartPage.css";
import AppLogout from "../app/auth/AppLogout.js";

export default function App() {
  const isMobile = useIsMobile();

  const standardContent = (
    <AppLogout>
      <Nav />
      <ListMaker />
      <LowerNav />
      <ToDoMaker />
    </AppLogout>
  );

  const mobileContent = (
    <div className="mobile_body_container">
      <AppLogout>
        <NavMobile />
        <TooSeeListMobileTitle />
        <TooSeeListMobile />
      </AppLogout>
    </div>
  );

  return <>{!isMobile ? standardContent : mobileContent}</>;
}

import { React, useState } from "react";

//query selector

//style imports
import styles from "../css/Settings.module.css";

//components
import Nav from "../components/navigation/Nav";
import AddUserName from "../features//standard/user/AddUserName";
import WhiteButton from "../components/buttons/WhiteButton";
import ViewAllUsers from "../features/standard/user/ViewAllUsers";
import ViewAllAssessments from "../features/standard/user/ViewAllAssessments";

const Settings = () => {
  const [toggle, setToggle] = useState({
    resinputs: false,
    addnew: false,
    viewall: false,
    changepwd: false,
    assess: false,
  });

  const handleToggle = (e) => {
    const name = e.target.name;

    switch (name) {
      case "Long Term Resident Inputs":
        setToggle((prev) => ({
          ...prev,
          resinputs: !prev.resinputs,
          addnew: false,
          viewall: false,
          changepwd: false,
          assess: false,
        }));
        break;
      case "Add New User":
        setToggle((prev) => ({
          ...prev,
          resinputs: false,
          addnew: !prev.addnew,
          viewall: false,
          changepwd: false,
          assess: false,
        }));
        break;
      case "View All Users":
        setToggle((prev) => ({
          ...prev,
          resinputs: false,
          addnew: false,
          viewall: !prev.viewall,
          changepwd: false,
          assess: false,
        }));
        break;
      case "Change Password":
        setToggle((prev) => ({
          ...prev,
          resinputs: false,
          addnew: false,
          viewall: false,
          changepwd: !prev.changepwd,
          assess: false,
        }));
        break;
      case "View All Assessments":
        setToggle((prev) => ({
          ...prev,
          resinputs: false,
          addnew: false,
          viewall: false,
          changepwd: false,
          assess: !prev.uichnge,
        }));
        break;
      default:
        console.log("no name");
    }
  };

  return (
    <div>
      <Nav />
      <div className={styles.settings_navigation}>
        <WhiteButton name="Add New User" event={handleToggle} />
        <WhiteButton name="View All Users" event={handleToggle} />
        <WhiteButton name="Change Password" event={handleToggle} />
        <WhiteButton name="View All Assessments" event={handleToggle} />
      </div>
      {toggle.addnew && (
        <div>
          <AddUserName />
        </div>
      )}
      {toggle.viewall && (
        <div>
          <ViewAllUsers />
        </div>
      )}
      {toggle.changepwd && (
        <div>
          <ViewAllUsers />
        </div>
      )}
      {toggle.assess && (
        <div>
          <ViewAllAssessments />
        </div>
      )}
    </div>
  );
};

export default Settings;

import { NESTED_IDS, problemList } from "../../../../utils/staticData.js";
import CopyToClipboard from "react-copy-to-clipboard";

//styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import buttonStyles from "../../../../components/buttons/css/Button.module.css";
import styles from "../../../../css/DataEntry.module.css";

export default function NutritionDiagnosis({
  item,
  handleNestedchange,
  handleRemove,
}) {
  return (
    <>
      <div className={styles.data_entry_nutrition_diagnosis_title_block}>
        <h5 className={styles.data_entry_nutrition_diagnosis_title}>
          Diagnosis #{item.num}
        </h5>
        {item.num >= 2 && (
          <span>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) =>
                handleRemove(NESTED_IDS.nutritionDiagnosis, item.id)
              }
              style={{
                color: "rgb(241, 250, 238)",
                cursor: "pointer",
                paddingLeft: "6px",
              }}
            />
          </span>
        )}
      </div>
      <div className={styles.data_entry_nutrition_diagnosis}>
        <span>Problem</span>
        <select
          name="nutritionDiagnosis.problem"
          value={item.problem}
          onChange={(e) => handleNestedchange(e, item.id)}>
          {problemList.map((item) => (
            <option value={item} key={item.toLowerCase()}>
              {item}
            </option>
          ))}
        </select>
        <span></span>

        <span>Etiology</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.etiology"
          value={item.etology}
          defaultValue={item.etiology}
          onChange={(e) => handleNestedchange(e, item.id)}></textarea>
        <CopyToClipboard text={item.etiology} onCopy={(text) => text}>
          <button className={buttonStyles.button}>Copy</button>
        </CopyToClipboard>

        <span>Symptoms</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.symptom"
          value={item.symptom}
          defaultValue={item.symptom}
          onChange={(e) => handleNestedchange(e, item.id)}></textarea>
        <CopyToClipboard text={item.symptom} onCopy={(text) => text}>
          <button className={buttonStyles.button}>Copy</button>
        </CopyToClipboard>
      </div>
    </>
  );
}

import { WT_CHANGE_TYPE } from "../../../../utils/staticData.js";
import styles from "../../../../css/Assessment.module.css";
import {
  lbsChangeCalculation,
  percentChangeCalc,
  dateChange,
} from "../../../../utils/helperFunctions.js";

const WtChangeInputs = ({ w, inputs, handleNestedChange }) => {
  const wtchange = lbsChangeCalculation(Number(inputs.wt), Number(w.wtchange));
  const percentchange = percentChangeCalc(
    Number(inputs.wt),
    Number(w.wtchange)
  );
  const datechange = dateChange(inputs.currWtDate, w.wtdate);

  return (
    <>
      <div className={styles.form_wt_change}>
        <span>{w.wtid} Month Wt:</span>
        <input
          type="number"
          name="wtChange.wtchange"
          className={styles.wt_change_spinner}
          value={Number(w.wtchange) > 0 ? Number(w.wtchange) : ""}
          onChange={(e) => handleNestedChange(e, w.id)}
          style={{ width: "2rem" }}
        />
        <span>lbs</span>
        <input
          type="date"
          name="wtChange.wtdate"
          placeholder="Date Obtained"
          value={w.wtdate}
          onChange={(e) => handleNestedChange(e, w.id)}
        />
        <span>Use: </span>
        <input
          type="checkbox"
          name="wtChange.toggle"
          checked={w.toggle}
          onChange={(e) => handleNestedChange(e, w.id)}
        />
      </div>
      {w.toggle && (
        <div>
          <span
            style={{
              paddingLeft: "0.25rem",
            }}>
            {w.wtchange &&
              `${w.wtid} Month${
                w.wtid !== "1" ? "s" : ""
              } Change: ${wtchange.toFixed(1)} lbs./ ${percentchange.toFixed(
                1
              )}%`}
            {datechange && ` @ ${datechange} Days`}
          </span>
          <select
            name="wtChange.wtchangetype"
            value={w.wtchangetype}
            onChange={(e) => handleNestedChange(e, w.id)}
            style={{
              marginLeft: "0.25rem",
            }}>
            <option></option>
            {WT_CHANGE_TYPE.map((w, i) => (
              <option key={i} value={w}>
                {w}
              </option>
            ))}
          </select>
        </div>
      )}
      {w.wtchangetype === "Significant Weight Loss" ||
      w.wtchangetype === "Significant Weight Gain" ? (
        <div>
          <span>{w.wtchangetype} secondary to</span>
          <input
            type="text"
            name="wtChange.reason"
            value={w.reason}
            onChange={(e) => handleNestedChange(e, w.id)}
          />
        </div>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default WtChangeInputs;

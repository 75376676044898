import WhiteButton from "../../../components/buttons/WhiteButton";

import styles from "../../../css/Weights.module.css";

function WeightNav({ location }) {
  console.log(location);

  let content;

  if (location === "bwt") {
    content = (
      <div className={styles.lower_nav_container} style={{ width: "40rem" }}>
        <WhiteButton name="A-Wing" />
        <WhiteButton name="A-Wing Weekly" />
        <WhiteButton name="B-Wing" />
        <WhiteButton name="B-Wing Weekly" />
        <WhiteButton name="C-Wing" />
      </div>
    );
  }

  if (location === "wr") {
    content = (
      <div className={styles.lower_nav_container} style={{ width: "40rem" }}>
        <WhiteButton name="Cedar" />
        <WhiteButton name="Juniper" />
      </div>
    );
  }

  return content;
}

export default WeightNav;

import { useState } from "react";

//styles
import styles from "../../../css/Settings.module.css";
import globalStyles from "../../../css/globalStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import StandardButton from "../../../components/buttons/StandardButton";

const EditUser = ({ user, handleEditClosed }) => {
  const [status, setStatus] = useState(user.active);
  const [twoFA, setTwoFA] = useState(user.enable2fa);
  const [toggle, setToggle] = useState(false);

  const handleStatus = (e) => setStatus(e.target.value);
  const handleTwoFA = (e) => setTwoFA(e.target.value);

  const handleSubmit = () => {};

  return (
    <div className={styles.settings_edit_user}>
      <div className={styles.settings_edit_user_top_row}>
        <h4>Edit User</h4>
        <FontAwesomeIcon
          icon={faXmark}
          size="md"
          onClick={handleEditClosed}
          className={globalStyles.font_awesome_buttons}
        />
      </div>
      <div className={styles.settings_edit_user_body}>
        <div className={styles.settings_edit_user_inner_content}>
          <h7>Username</h7>
          <span className={styles.settings_edit_username}>{user.username}</span>
          <h7>Set Active Status</h7>
          <select value={status} onChange={handleStatus}>
            <option></option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
          {user.enable2fa && (
            <>
              <h7>Set 2FA Status</h7>
              <select value={twoFA} onChange={handleTwoFA}>
                <option></option>
                <option value={true}>Enabled</option>
                <option value={false}>Disabled</option>
              </select>
            </>
          )}
          <h7>Reset Password</h7>
          <label className={styles.toggle_switch}>
            <input
              type="checkbox"
              value={toggle}
              onChange={() => setToggle((prev) => !prev)}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        {toggle && (
          <div className={styles.settings_temp_password}>
            <h7>Temporary Password</h7>
            <input type="text" />
          </div>
        )}
      </div>
      <div className={styles.button_row}>
        <StandardButton title="Set" event={handleSubmit} />
      </div>
    </div>
  );
};

export default EditUser;

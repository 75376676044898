//hooks
import AppLogout from "../app/auth/AppLogout";
import useAuth from "../hooks/useAuth";

//components
import Nav from "../components/navigation/Nav";
import WeightInput from "../features/standard/weights/WeightInput";
import WeightNav from "../features/standard/weights/WeightNav";
import WeightChart from "../features/standard/weights/WeightChart";

function Weights() {
  const { location } = useAuth();

  return (
    <AppLogout>
      <Nav />
      <WeightInput location={location} />
      <WeightNav location={location} />
      <WeightChart />
    </AppLogout>
  );
}

export default Weights;

import { useSelector } from "react-redux";
import {
  selectAllLists,
  useDeleteItemMutation,
  useUpdateItemMutation,
} from "../../../app/api/listApiSlice";
import { useState } from "react";
import { faCheck, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAuth from "../../../hooks/useAuth";
import StandardButton from "../../../components/buttons/StandardButton";

export default function ListItems({ type, assess, assessType }) {
  const list = useSelector((state) => selectAllLists(state));

  const { username, location } = useAuth();

  const [assessNum, setAssessNum] = useState("");

  const [updateItem] = useUpdateItemMutation();

  const [deleteItem] = useDeleteItemMutation();

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateItem({ id: e.target.name, assessnum: Number(assessNum) });
    setAssessNum("");
  };

  const onDeleteItemClicked = async (id) => {
    await deleteItem({ id });
  };

  return list
    .filter(
      (p) =>
        p.assessType === type && p.user === username && p.location === location
    )
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((res, i) => (
      <tr key={i} className="inner_container_list_row">
        <td className="inner_container_first_td">
          <select
            className="inner_container_list_select"
            onChange={(e) => setAssessNum(e.target.value)}>
            <option value=""></option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
          <StandardButton event={handleUpdate} name={res.id} title="Add" />
        </td>
        <td>
          <span>
            (
            {new Date(res.date).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })}
            )
          </span>
        </td>
        <td>
          <span>
            {res.lastName}, {res.firstName}{" "}
          </span>
        </td>
        {assess.some((a) => a === assessType) && (
          <td>
            {res.ltentry ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: "rgb(241, 250, 238)" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faTimes}
                style={{ color: "rgb(241, 250, 238)" }}
              />
            )}
          </td>
        )}
        <td>
          <span>{res.room}</span>
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => onDeleteItemClicked(res.id)}
            style={{
              color: "rgb(241, 250, 238)",
              cursor: "pointer",
            }}
          />
        </td>
      </tr>
    ));
}

import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAddNewListItemMutation } from "../../../app/api/listApiSlice";
import { BWT_ROOMS, WR_ROOMS } from "../../../utils/staticData";
import useAuth from "../../../hooks/useAuth";
import "../../../css/StartPage.css";
import { setLTData } from "./startPageSlice";

export default function Lister({ users }) {
  const [addNewItem, { isLoading, isSuccess, isError, error }] =
    useAddNewListItemMutation();

  const { username, location } = useAuth();

  const [admitDate, setAdmitDate] = useState("");
  const [ageDate, setAgeDate] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [assessType, setAssessType] = useState("");
  const [room, setRoom] = useState("");

  let roomList;

  if (location === "bwt") {
    roomList = BWT_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  if (location === "wr") {
    roomList = WR_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  const dispatch = useDispatch();
  const addedUser = useSelector((state) => state.addLT);

  useEffect(() => {
    setLastName(addedUser.lastName);
    setFirstName(addedUser.firstName);
    setAgeDate(addedUser.dob);
  }, [addedUser]);

  useEffect(() => {
    if (isSuccess) {
      setAdmitDate("");
      setAgeDate("");
      setLastName("");
      setFirstName("");
      setAssessType("");
      setRoom("");
    }
  }, [isSuccess]);

  const onLastNameChanged = (e) => setLastName(e.target.value);
  const onFirstNameChanged = (e) => setFirstName(e.target.value);
  const onTypeChanged = (e) => setAssessType(e.target.value);
  const onRoomChanged = (e) => setRoom(e.target.value);

  const onAdmitDateChanged = (e) => {
    setAdmitDate(e.target.value);
  };

  const onAgeDateChanged = (e) => {
    setAgeDate(e.target.value);
  };

  const handleClear = () => {
    setAdmitDate("");
    setAgeDate("");
    setLastName("");
    setFirstName("");
    setAssessType("");
    setRoom("");
    dispatch(setLTData({ lastName: "", firstName: "", dob: "" }));
  };

  const canSave =
    [admitDate, ageDate, lastName, firstName, assessType, room].every(
      Boolean
    ) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (canSave) {
      await addNewItem({
        date: admitDate,
        age: ageDate,
        lastName,
        firstName,
        assessType,
        room,
        assessnum: null,
        user: username,
        location,
      });
    }
    dispatch(setLTData({ lastName: "", firstName: "", dob: "" }));
  };

  const content = (
    <div class="name-submitter">
      <section class="list_maker_inputs">
        <div className="input_container_label">
          <input
            className="input"
            type="date"
            name="date"
            onChange={onAdmitDateChanged}
            pattern=""
            style={{ width: "7.5rem" }}
            value={admitDate}
          />
          <span>Admit/ARD Date</span>
        </div>
        <div className="input_container_label">
          <input
            className="input"
            type="date"
            name="date"
            onFocus={(e) => (e.target.type = "date")}
            onChange={onAgeDateChanged}
            pattern=""
            style={{ width: "7.5rem" }}
            value={ageDate}
          />
          <span>Date of Birth</span>
        </div>
        <div className="input_container_label">
          <input
            type="text"
            name="lastName"
            className="input"
            onChange={onLastNameChanged}
            value={lastName}
          />
          <span>Last Name</span>
        </div>
        <div className="input_container_label">
          <input
            type="text"
            name="firstName"
            className="input"
            onChange={onFirstNameChanged}
            value={firstName}
          />
          <span>First Name</span>
        </div>
        <div className="input_container_label">
          <select className="input" value={room} onChange={onRoomChanged}>
            <option value=""></option>
            {roomList}
          </select>
          <span>Room Number</span>
        </div>
        <div className="input_container_label">
          <select
            name="type"
            className="input"
            id=""
            onChange={onTypeChanged}
            value={assessType}>
            <option value=""></option>
            <option value="Admission">Admission</option>
            <option value="Readmission">Readmission</option>
            <option value="Significant Change">Significant Change</option>
            <option value="Annual">Annual</option>
            <option value="Quarterly">Quarterly</option>
            <option value="PRN">PRN</option>
          </select>
          <span>Assess Type</span>
        </div>
      </section>
      <section class="list_maker_buttons">
        <button className="button" onClick={handleSubmit}>
          Add
        </button>

        <button className="button" onClick={handleClear}>
          Clear
        </button>
        {isError && (
          <span style={{ marginLeft: "1rem" }}>{error.data?.message}</span>
        )}
      </section>
    </div>
  );

  return content;
}

import {
  ageCalc,
  getBMI,
  selectUBW,
  getBmiType,
  percentChangeCalc,
  lbsChangeCalculation,
  dateChange,
  dietTypeChecker,
} from "./helperFunctions";

export const nutritionSummaryFunction = (data) => {
  const ptType = data.clientType;
  const bmi = bmiNote(data);
  const assessType = data.assessType;
  const foodIntake = foodIntakeReference(data);
  const dietType = dietTypeChecker(data);

  const FinalNote = [
    noteP1(data, assessType, ptType),
    noteP2(data, assessType),
    noteP3(data, assessType, bmi),
    noteP4(data),
    noteP5(data, dietType, ptType, foodIntake),
    noteP6(data),
  ];
  return FinalNote.join("");
};

function noteP1(data, assessType, ptType) {
  if (assessType === "Admission") {
    return `${ptType} is admitted to BTHC from ${data.admitLocal} with ${data.admitType}. `;
  } else if (assessType === "Readmission") {
    return `${ptType} is admitted to BTHC from ${data.admitLocal} with ${data.admitType}. `;
  } else if (assessType === "Annual") {
    return `${ptType} is a ${ageCalc(data.age)} years old ${data.sex} with `;
  } else if (assessType === "Significant Change") {
    return `${ptType} is a ${ageCalc(data.age)} years old ${
      data.sex
    } with significant change of ${data.admitType} `;
  } else {
    return "";
  }
}

function noteP2(data, assessType) {
  if (
    data.pmhx &&
    (assessType === "Admission" || assessType === "Readmission")
  ) {
    return `PMHx of ${data.pmhx}. `;
  } else if (data.pmhx && assessType === "Significant Change") {
    return `PMHx of ${data.pmhx}. ${wtChange(data)}`;
  } else if (
    assessType === "Annual" ||
    assessType === "Quarterly" ||
    assessType === "PRN"
  ) {
    return wtChange(data);
  } else {
    return "";
  }
}

function noteP3(data, assessType, bmi) {
  const wtDate = new Date(data.currWtDate).toLocaleDateString();
  const ubwStart = data.ubwLow;
  let currWt;
  if (data.wt && ubwStart) {
    currWt = `Current wt is (${wtDate}) ${data.wt} lbs`;
  }
  if (data.wt) {
    currWt = `Current wt is (${wtDate}) ${data.wt} lbs`;
  }

  if (
    bmi &&
    (assessType === "Admission" ||
      assessType === "Readmission" ||
      assessType === "Quarterly" ||
      assessType === "Annual")
  ) {
    return `${currWt}. ${bmi}. `;
  } else if (
    assessType === "Significant Change" ||
    assessType === "Annual" ||
    assessType === "Quarterly" ||
    assessType === "PRN"
  ) {
    return `${currWt}${ubwRangeChecker(data)} `;
  } else {
    return "";
  }
}

function noteP5(data, dietType, ptType, foodIntake) {
  if (dietType) {
    return ` ${ptType} with ${foodIntake} intake${
      dietTypeChecker(data) && ` on ${dietTypeChecker(data)}`
    }. `;
  } else {
    return "";
  }
}

function noteP4(data) {}

function noteP6(data) {
  if (data.currSummary) {
    return `${data.currSummary}`;
  } else {
    return "";
  }
}

function wtChange(data) {
  const recentWt = data.wt;
  const ptType = data.clientType;
  const [oneMonthWt, threeMonthWt, sixMonthWt] = data.wtChange;
  const oneMonthWtDate = new Date(oneMonthWt.wtdate).toLocaleDateString();
  const oneMonthWtChange = oneMonthWt.wtchange;
  const threeMonthWtDate = new Date(threeMonthWt.wtdate).toLocaleDateString();
  const threeMonthWtChange = threeMonthWt.wtchange;
  const sixMonthWtDate = new Date(sixMonthWt.wtdate).toLocaleDateString();
  const sixMonthWtChange = sixMonthWt.wtchange;

  const oneMonthChange = `(${oneMonthWtDate}) ${oneMonthWtChange} lbs. [${percentChangeCalc(
    recentWt,
    oneMonthWtChange
  ).toFixed(1)}% / ${lbsChangeCalculation(recentWt, oneMonthWtChange).toFixed(
    1
  )} lbs @ ${dateChange(data.currWtDate, oneMonthWt.wtdate)} days]`;

  const threeMonthChange = `(${threeMonthWtDate}) ${threeMonthWtChange} lbs. [${percentChangeCalc(
    recentWt,
    threeMonthWtChange
  ).toFixed(1)}% / ${lbsChangeCalculation(recentWt, threeMonthWtChange).toFixed(
    1
  )} lbs @ ${dateChange(data.currWtDate, threeMonthWt.wtdate)} days]`;

  const sixMonthChange = `(${sixMonthWtDate}) ${sixMonthWtChange} lbs. [${percentChangeCalc(
    recentWt,
    sixMonthWtChange
  ).toFixed(1)}% / ${lbsChangeCalculation(recentWt, sixMonthWtChange).toFixed(
    1
  )} lbs @ ${dateChange(data.currWtDate, sixMonthWt.wtdate)} days]`;

  if (oneMonthWt.toggle && !threeMonthWt.toggle && !sixMonthWt.toggle) {
    return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. `;
  } else if (!oneMonthWt.toggle && threeMonthWt.toggle && !sixMonthWt.toggle) {
    return `${ptType} has ${threeMonthWt.wtchangetype.toLowerCase()} within the past month ${threeMonthChange}. `;
  } else if (!oneMonthWt.toggle && !threeMonthWt.toggle && sixMonthWt.toggle) {
    return `${ptType} has ${sixMonthWt.wtchangetype.toLowerCase()} within the past month ${sixMonthChange}. `;
  } else if (oneMonthWt.toggle && threeMonthWt.toggle && !sixMonthWt.toggle) {
    return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. Also, has ${
      threeMonthWt.wtchangetype
    } within the past three months ${threeMonthChange}. `;
  } else if (oneMonthWt.toggle && !threeMonthWt.toggle && sixMonthWt.toggle) {
    return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. Also, has ${sixMonthWt.wtchangetype.toLowerCase()} within the past six months ${sixMonthChange} `;
  } else if (!oneMonthWt.toggle && threeMonthWt.toggle && sixMonthWt.toggle) {
    return `${ptType} has ${threeMonthWt.wtchangetype.toLowerCase()} within the past three months ${threeMonthChange}. Also, has ${
      sixMonthWt.wtchangetype
    } within the past six months ${sixMonthChange} `;
  } else if (oneMonthWt.toggle && threeMonthWt.toggle && sixMonthWt.toggle) {
    return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}, Also has ${threeMonthWt.wtchangetype.toLowerCase()} within the past three months ${threeMonthChange}, and has ${sixMonthWt.wtchangetype.toLowerCase()} within the past six months ${sixMonthChange} `;
  } else if (
    !recentWt &&
    data.weightOrder !== "Weights D/C'd" &&
    (data.assessType === "Quarterly" ||
      data.assessType === "PRN" ||
      data.assessType === "Annual" ||
      data.assessType === "Significant Change")
  ) {
    return `${ptType} without weight taken within the past 30 days. `;
  } else {
    return;
  }
}

function ubwRangeChecker(data) {
  const ubwStart = data.ubwLow;
  const ubwEnd = data.ubwHigh;
  const ubwPercentage = selectUBW(data.wt, ubwStart, ubwEnd);
  const ubwMonths = data.ubwMonths;
  let ubwLevel;

  const percentCalc =
    ubwPercentage !== 0.0 ? `${ubwPercentage?.toFixed(1)}%` : "";

  if (ubwPercentage === 0.0) {
    ubwLevel = "within";
  } else if (ubwPercentage > 0.0) {
    ubwLevel = "above";
  } else {
    ubwLevel = "below";
  }

  if (ubwStart) {
    return `, which is ${
      ubwPercentage === 0.0
        ? `${ubwLevel} usual body weight range (${ubwStart} - ${ubwEnd} lbs)`
        : `${percentCalc} ${ubwLevel} usual body weight range (${ubwStart} - ${ubwEnd} lbs)`
    }. Usual Body Weight is Average Weight Range (+/-10 lbs) within the past ${ubwMonths} months.`;
  } else {
    return ". ";
  }
}

function foodIntakeReference(data) {
  const foodIntake = data.foodIntake;

  if (foodIntake === ">50%") return "fair to good";
  if (foodIntake === "~50%") return "fair";
  if (foodIntake === "<50%") return "poor";
  return;
}

function bmiNote(data) {
  const bodyMassIndex = getBMI(data.ht, data.wt);
  const bodyMassIndexName = getBmiType(data.ht, data.wt);

  if (data.recentWt > 0 && data.ht > 0) {
    return `BMI of ${bodyMassIndex.toFixed(2)} (${bodyMassIndexName}) noted`;
  } else {
    return;
  }
}

//static Data
import { MULTI_OBJ } from "../../../../utils/staticData";

//utility functions
import {
  ageCalc,
  equationCalculation,
} from "../../../../utils/helperFunctions";

//styles
import styles from "../../../../css/Assessment.module.css";

const Equations = ({ inputs, handleChange }) => {
  const age = ageCalc(inputs.age);
  const equations = equationCalculation(inputs, age, inputs.sex);

  let assignInj;

  if (inputs.injType === "surgery") {
    assignInj = MULTI_OBJ.injury
      .filter((item) => item.type === "surgery")
      .map((item, i) => (
        <option value={item.number} key={i}>
          {item.name}
        </option>
      ));
  }
  if (inputs.injType === "infection")
    assignInj = MULTI_OBJ.injury
      .filter((item) => item.type === "infection")
      .map((item, i) => (
        <option value={item.number} key={i}>
          {item.name}
        </option>
      ));
  if (inputs.injType === "trauma")
    assignInj = MULTI_OBJ.injury
      .filter((item) => item.type === "trauma")
      .map((item, i) => (
        <option value={item.number} key={i}>
          {item.name}
        </option>
      ));
  if (inputs.injType === "burns") {
    assignInj = MULTI_OBJ.injury
      .filter((item) => item.type === "burns")
      .map((item, i) => (
        <option value={item.number} key={i}>
          {item.name}
        </option>
      ));
  }

  return (
    <div className={styles.form_calorie_calculation}>
      <span>Mifflin-St. Jeor:</span>
      <span style={{ marginLeft: "0.5rem", marginRight: "1.5rem" }}>
        {equations.mfsEquation > 0 && inputs.ht && inputs.wt
          ? `${equations.mfsEquation.toFixed(0)} Calories`
          : 0}
      </span>
      <span>Livingston:</span>
      <span style={{ marginLeft: "0.5rem", marginRight: "1.5rem" }}>
        {equations.livEquation > 0 && inputs.ht && inputs.wt
          ? `${equations.livEquation.toFixed(0)} Calories`
          : 0}
      </span>
      <span>Activity Factor:</span>
      <select name="actFactor" value={inputs.actFactor} onChange={handleChange}>
        {MULTI_OBJ.activity.map((i, k) => (
          <option value={i.number} key={k}>
            {i.name}
          </option>
        ))}
      </select>

      <span>Injury Factor Type:</span>
      <select name="injType" value={inputs.injType} onChange={handleChange}>
        <option value="">None</option>
        <option value="surgery">Surgery</option>
        <option value="infection">Infection</option>
        <option value="trauma">Trauma</option>
        <option value="burns">Burns</option>
      </select>
      {inputs.injType && (
        <>
          <span>Injury Factor Sub-Type:</span>
          <select
            name="injFactor"
            value={inputs.injFactor}
            onChange={handleChange}>
            <option value="0">None</option>
            {assignInj}
          </select>
        </>
      )}
    </div>
  );
};

export default Equations;

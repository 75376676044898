//Static Data
import { BWT_ROOMS, WR_ROOMS } from "../../../utils/staticData";

//Styles
import styles from "../../../css/Weights.module.css";

function WeightInput({ location }) {
  let roomList;

  if (location === "bwt") {
    roomList = BWT_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  if (location === "wr") {
    roomList = WR_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  return (
    <div className={styles.weight_submitter}>
      <section className={styles.weight_maker_inputs}>
        <input
          type="date"
          placeholder="Date of Birth"
          style={{ width: "7.5rem" }}
        />
        <input type="text" name="lastName" placeholder="Last Name" />
        <input type="text" name="firstName" placeholder="First Name" />
        <select>
          <option value="">Room #</option>
          {roomList}
        </select>
      </section>
      <section className={styles.weight_maker_buttons}>
        <button>Add</button>
        <button>Clear</button>
      </section>
    </div>
  );
}

export default WeightInput;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { malnutritionRiskFunction } from "../../../../utils/summaryHelperFunctions";
import { getBMI, getBmiType, setIBW } from "../../../../utils/helperFunctions";

export default function MalnutritionPDF() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();
  const data = location.state;

  const malnutritionRiskScreen = malnutritionRiskFunction(data);
  const malNum = Number(malnutritionRiskScreen.total.num);
  const percentIBW = data.wt / setIBW(data.ht, data.sex);
  const currentDate = new Date().toLocaleDateString("en");

  function malnutritionScreening() {
    if (malNum >= 0 && malNum <= 7 && percentIBW >= 0.911)
      return "Unable to Stage Secondary to IBW > 90%";
    if (malNum >= 0 && malNum <= 7 && percentIBW <= 0.91 && percentIBW >= 0.75)
      return "Mild Protein Calorie Malnutrition";
    if (malNum >= 0 && malNum <= 7 && percentIBW <= 0.749 && percentIBW >= 0.6)
      return "Moderate Protein Calorie Malnutrition";
    if (malNum >= 0 && malNum <= 7 && percentIBW <= 0.599)
      return "Severe Protein Calorie Malnutrition";
    return;
  }

  const styles = StyleSheet.create({
    viewer: {
      height: windowHeight,
      width: windowWidth,
      position: "relative",
      zIndex: 1,
    },
    page: {
      color: "black",
      fontSize: "10.75px",
    },
    sectionOne: {
      margin: 25,
      padding: 5,
      flexDirection: "row",
    },
    sectionTwo: {
      flexDirection: "row",
      marginLeft: "30%",
    },
    sectionThree: {
      flexDirection: "row",
      paddingTop: 6,
      paddingBottom: 3,
      marginLeft: 25,
      justifyContent: "space-between",
      width: "75%",
      borderBottom: "black",
      borderBottomWidth: "2px",
    },
    sectionFour: {
      width: "65%",
      marginLeft: 50,
      paddingTop: 6,
    },
    sectionFive: {
      flexDirection: "row",
      marginLeft: 25,
      justifyContent: "space-between",
      width: "75%",
    },
    sectionSix: {
      flexDirection: "row",
      width: 200,
      justifyContent: "space-between",
      padding: 3,
    },
    sectionSeven: {
      flexDirection: "row",
      marginLeft: 25,
      marginTop: 5,
    },
    sectionEight: {
      flexDirection: "row",
      fontSize: "8px",
      marginLeft: 25,
      marginRight: 25,
      marginTop: 5,
      width: "85%",
    },
    sectionNine: {
      flexDirection: "row",
      marginTop: 5,
      marginLeft: 25,
      width: 600,
    },
    malStaging: {
      flexDirection: "row",
      paddingTop: 5,
      marginLeft: 25,
    },
    numberSection: {
      justifyContent: "flex-end",
    },
    choiceSectionSpacing: { width: "80%" },
    underline: { textDecoration: "underline" },
    sectionOneSpace: { marginLeft: "10px" },
  });

  return (
    <PDFViewer size={[windowWidth, windowHeight]} style={styles.viewer}>
      <Document title={`${data.lastName}.${data.firstName}`}>
        <Page size="Letter" style={styles.page}>
          <View style={styles.sectionOne}>
            <Text>Name: </Text>
            <Text
              style={
                styles.underline
              }>{`${data.lastName}, ${data.firstName}`}</Text>
            <Text style={styles.sectionOneSpace}>Room: </Text>
            <Text style={styles.underline}>{data.room}</Text>
          </View>
          <View style={styles.sectionTwo}>
            <Text>Malnutrition Risk Screen</Text>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>A.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>
                Has food intake delined over the past 3 months due to loss of
                appetite, digestive problems, chewing or swallowing
                difficulties?
              </Text>
              <Text>0 = Severe decrease in food intake</Text>
              <Text>1 = Moderate decrease in food intake</Text>
              <Text>2 = No decrease in food intake</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.A.num}</Text>
            </View>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>B.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>Weight Loss during the last 3 months</Text>
              <Text>0 = Weight loss greater than 3 kg (6.6lbs)</Text>
              <Text>1 = Does not know</Text>
              <Text>2 = Weight loss between 1 and 3 kg (2.2 and 6.6 lbs)</Text>
              <Text>3 = No weight loss</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.B.num}</Text>
            </View>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>C.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>Mobility</Text>
              <Text>0 = Bed or chair bound</Text>
              <Text>1 = Able to get out of bed/chair but does not go out</Text>
              <Text>2 = Goes out</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.C.num}</Text>
            </View>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>D.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>
                Has suffered psychological stres or acute disease in the past 3
                months?
              </Text>
              <Text>0 = Yes</Text>
              <Text>2 = No</Text>
              {data.values.D.num === "0" ? (
                <Text>Reason: {data.malInput}</Text>
              ) : (
                ""
              )}
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.D.num}</Text>
            </View>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>E.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>Neuropsychological problems:</Text>
              <Text>0 = Severe dementia or depression</Text>
              <Text>1 = Mild dementia</Text>
              <Text>2 = No psychological problems</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.E.num}</Text>
            </View>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>F1.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>Body Mass Index (BMI) (weight in kg/height in m)^2</Text>
              <Text>0 = BMI less than 19</Text>
              <Text>1 = BMI 19 to less than 21</Text>
              <Text>2 = BMI 21 to less than 23</Text>
              <Text>3 = BMI 23 or greater</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>Value: {data.values.F1.num}</Text>
            </View>
          </View>
          <View style={styles.sectionFour}>
            <Text>
              IF BMI IS NOT AVAILABLE REPLACE QUESTION F1 WITH QUESTION F2. DO
              NOT ANSWER QUESTION F2 IF QUESTION F1 IS ALREADY COMPLETED.
            </Text>
          </View>
          <View style={styles.sectionThree}>
            <View>
              <Text>F2.</Text>
            </View>
            <View style={styles.choiceSectionSpacing}>
              <Text>Calf circumference (CC) in cm</Text>
              <Text>0 = CC less than 31</Text>
              <Text>3 = CC 31 or greater</Text>
            </View>
            <View style={styles.numberSection}>
              <Text>
                Value: {data.values.F2.num ? data.values.F2.num : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.sectionFive}>
            <View>
              <Text>Screening score</Text>
              <Text>(max. 14 points)</Text>
              <View
                style={
                  malNum > 12
                    ? {
                        flexDirection: "row",
                        border: "black",
                        borderWidth: "2.5px",
                        paddingLeft: 5,
                        paddingRight: 5,
                        justifyContent: "space-between",
                      }
                    : styles.sectionSix
                }>
                <Text>12-14 points</Text>
                <Text>{malNum >= 12 ? "X" : ""}</Text>
                <Text>Normal nutrition status</Text>
              </View>
              <View
                style={
                  malNum >= 8 && malNum <= 11
                    ? {
                        flexDirection: "row",
                        border: "black",
                        borderWidth: "2.5px",
                        paddingLeft: 5,
                        paddingRight: 5,
                        justifyContent: "space-between",
                      }
                    : styles.sectionSix
                }>
                <Text>8-11 points</Text>
                <Text style={styles.border}>
                  {malNum >= 8 && malNum <= 11 ? "X" : ""}
                </Text>
                <Text>At risk of malnutrition</Text>
              </View>
              <View
                style={
                  malNum > 0 && malNum <= 7
                    ? {
                        flexDirection: "row",
                        border: "black",
                        borderWidth: "2.5px",
                        paddingLeft: 5,
                        paddingRight: 5,
                        justifyContent: "space-between",
                      }
                    : styles.sectionSix
                }>
                <Text>0-7 points</Text>
                <Text style={styles.border}>
                  {malNum > 0 && malNum <= 7 ? "X" : ""}
                </Text>
                <Text>Malnourished</Text>
              </View>
            </View>
            <Text>Total: {malnutritionRiskScreen.total.num}</Text>
          </View>
          <View style={styles.sectionSeven}>
            <Text>Current Wt:</Text>
            <Text style={{ marginLeft: 5 }}>{data.wt} lbs</Text>
          </View>
          <View style={styles.sectionSeven}>
            <Text>BMI:</Text>
            <Text style={{ marginLeft: 5 }}>
              {getBMI(data.ht, data.wt).toFixed(2)} (
              {getBmiType(data.ht, data.wt)})
            </Text>
          </View>
          <View style={styles.sectionSeven}>
            <Text>IBW:</Text>
            <Text style={{ marginLeft: 5 }}>
              {setIBW(data.ht, data.sex)} lbs
            </Text>
          </View>
          <View style={styles.sectionSeven}>
            <Text>% IBW:</Text>
            <Text style={{ marginLeft: 5 }}>
              {Math.trunc(percentIBW * 100)} %
            </Text>
          </View>
          {malnutritionScreening() && (
            <View style={styles.malStaging}>
              <Text>Suggested Malnutrition Staging:</Text>
              <Text style={{ marginLeft: 5, fontWeight: "bold" }}>
                {malnutritionScreening()}
              </Text>
            </View>
          )}
          <View style={styles.sectionNine}>
            <View
              style={{
                flexDirection: "row",
                borderBottom: "2px solid black",
                marginRight: "10px",
                width: 200,
              }}>
              <Text>Completed By:</Text>
              <Text style={{ marginLeft: 10 }}>Allen Beier, RD</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                borderBottom: "2px solid black",
                width: 125,
              }}>
              <Text>Date:</Text>
              <Text style={{ marginLeft: 10 }}>{currentDate}</Text>
            </View>
          </View>
          <View style={styles.sectionEight}>
            <Text style={{ marginRight: 10 }}>Ref:</Text>
            <Text>
              {" "}
              Vellas B, Villars H, Abellan G, et al. Overview of the MNA® - Its
              History and Challenges. J Nutr Health Aging 2006;10:456-465.
              Rubenstein LZ, Harker JO, Salva A, Guigoz Y, Vellas B. Screening
              for Undernutrition in Geriatric Practice: Developing the
              Short-Form Mini Nutritional Assessment (MNA-SF). J. Geront
              2001;56A: M366-377. Guigoz Y. The Mini-Nutritional Assessment
              (MNA®) Review of the Literature - What does it tell us? J Nutr
              Health Aging 2006; 10:466-487. Kaiser MJ, Bauer JM, Ramsch C, et
              al. Validation of the Mini Nutritional Assessment Short-Form
              (MNA®-SF): A practical tool for identification of nutritional
              status. J Nutr Health Aging 2009; 13:782-788.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

import { store } from "../store";
import { listApiSlice } from "../api/listApiSlice";
import { usersApiSlice } from "../api/usersApiSlice";
import { longTermApiSlice } from "../api/longTermApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import React from "react";

const Prefetch = () => {
  useEffect(() => {
    console.log("subscribing");
    const list = store.dispatch(listApiSlice.endpoints.getList.initiate());
    const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate());
    const ltList = store.dispatch(
      longTermApiSlice.endpoints.getLongTermList.initiate()
    );

    return () => {
      console.log("unsubscribing");
      list.unsubscribe();
      users.unsubscribe();
      ltList.unsubscribe();
    };
  }, []);

  return <Outlet />;
};

export default Prefetch;

import CopyToClipboard from "react-copy-to-clipboard";

//styles
import styles from "./css/Button.module.css";

function CopyButton({ style, value }) {
  return (
    <div style={style}>
      <CopyToClipboard text={value}>
        <button type="button" className={styles.button}>
          Copy
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default CopyButton;
